import React, { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Header } from "./header";
import JsonData from "../data/data.json";

const Slidershow = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Slide autoplay={false}>
      <div className="each-slide-effect">
        <Header data={landingPageData.Header} />
      </div>
      <div className="each-slide-effect">
        <Header data={landingPageData.Header2} header="false" />
      </div>
      <div className="each-slide-effect">
        <Header data={landingPageData.Header3} header3="true" />
      </div>
    </Slide>
  );
};

export default Slidershow;
