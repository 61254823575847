import React from "react";
import { PageTitle } from "./pageTitle";

import "../App.css";

const SecurityUses = () => {
  return (
    <div>
      <PageTitle title="Política de Privacidade e Termos de Uso" />
      <div id="bodyContrato" className="text-just">
        <p>
          A sua privacidade é importante para nós. É política da FazBank
          respeitar a sua privacidade em relação a qualquer informação sua que
          possamos coletar no site FazBank, e outros sites que possuímos e
          operamos.
        </p>
        <p>
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemos por meios justos e legais,
          com o seu conhecimento e consentimento. Também informamos por que
          estamos coletando e como será usado.
        </p>
        <p>
          Apenas retemos as informações coletadas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos em
          meios comercialmente aceitáveis para evitar perdas e roubos, bem como
          acesso, divulgação, cópia, uso ou modificação não autorizada.
        </p>
        <p>
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
        </p>
        <p>
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade por suas
          respectivas políticas de privacidade.
        </p>
        <p>
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
        </p>
        <p>
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contato conosco.
        </p>

        <h5>Política de Cookies FazBank</h5>
        <h5>O que são cookies?</h5>
        <p>
          Como é prática comum em quase todos os sites profissionais, este site
          usa cookies, pequenos arquivos baixados no seu computador, para
          melhorar sua experiência. Esta página descreve quais informações eles
          coletam, como as usamos e por que às vezes precisamos armazenar esses
          cookies. Também compartilharemos como você pode impedir que esses
          cookies sejam armazenados, no entanto, isso pode fazer o downgrade ou
          ‘quebrar’ certos elementos da funcionalidade do site.
        </p>

        <h5>Como usamos os cookies?</h5>
        <p>
          Utilizamos cookies por vários motivos, detalhados abaixo.
          Infelizmente, na maioria dos casos, não existem opções padrão do setor
          para desativar os cookies sem desativar completamente a funcionalidade
          e os recursos que eles adicionam a este site. É recomendável que você
          deixe todos os cookies se não tiver certeza se precisa ou não deles,
          caso sejam usados para fornecer um serviço que você usa.
        </p>

        <h5>Desativar cookies</h5>
        <p>
          Você pode impedir a configuração de cookies ajustando as configurações
          do seu navegador (consulte a Ajuda do navegador para saber como fazer
          isso). Esteja ciente que a desativação de cookies afetará a
          funcionalidade deste e de muitos outros sites que você visita. A
          desativação de cookies geralmente resultará na desativação de
          determinadas funcionalidades e recursos deste site. Portanto, é
          recomendável que você não desative os cookies.
        </p>

        <h5>Cookies que definimos</h5>
        <ul>
          <li>
            <strong>Cookies relacionados à conta:</strong> Se você criar uma
            conta conosco, usaremos cookies para o gerenciamento do processo de
            inscrição e administração geral. Esses cookies serão geralmente
            excluídos quando você sair do sistema, porém, em alguns casos, eles
            poderão permanecer posteriormente para lembrar as preferências do
            seu site ao sair.
          </li>
          <li>
            <strong>Cookies relacionados ao login:</strong> Utilizamos cookies
            quando você está logado, para que possamos lembrar dessa ação. Isso
            evita que você precise fazer login sempre que visitar uma nova
            página. Esses cookies são normalmente removidos ou limpos quando
            você efetua logout para garantir que você possa acessar apenas a
            recursos e áreas restritas ao efetuar login.
          </li>
          <li>
            <strong>Cookies relacionados a boletins por e-mail:</strong> Este
            site oferece serviços de assinatura de boletim informativo ou e-mail
            e os cookies podem ser usados para lembrar se você já está
            registrado e se deve mostrar determinadas notificações válidas
            apenas para usuários inscritos / não inscritos.
          </li>
          <li>
            <strong>Pedidos processando cookies relacionados:</strong> Este site
            oferece facilidades de comércio eletrônico ou pagamento e alguns
            cookies são essenciais para garantir que seu pedido seja lembrado
            entre as páginas, para que possamos processá-lo adequadamente.
          </li>
          <li>
            <strong>Cookies relacionados a pesquisas:</strong> Periodicamente,
            oferecemos pesquisas e questionários para fornecer informações
            interessantes, ferramentas úteis ou para entender nossa base de
            usuários com mais precisão. Essas pesquisas podem usar cookies para
            lembrar quem já participou numa pesquisa ou para fornecer resultados
            precisos após a alteração das páginas.
          </li>
          <li>
            <strong>Cookies relacionados a formulários:</strong> Quando você
            envia dados por meio de um formulário como os encontrados nas
            páginas de contacto ou nos formulários de comentários, os cookies
            podem ser configurados para lembrar os detalhes do usuário para
            correspondência futura.
          </li>
          <li>
            <strong>Cookies de preferências do site:</strong> Para proporcionar
            uma ótima experiência neste site, fornecemos a funcionalidade para
            definir suas preferências de como esse site é executado quando você
            o usa. Para lembrar suas preferências, precisamos definir cookies
            para que essas informações possam ser chamadas sempre que você
            interagir com uma página for afetada por suas preferências.
          </li>
        </ul>

        <h5>Cookies de Terceiros</h5>
        <p>
          Em alguns casos especiais, também usamos cookies fornecidos por
          terceiros confiáveis. A seção a seguir detalha quais cookies de
          terceiros você pode encontrar através deste site.
        </p>
        <p>
          Este site usa o Google Analytics, uma das soluções de análise mais
          difundidas e confiáveis da Web, para nos ajudar a entender como você
          usa o site e como podemos melhorar sua experiência. Esses cookies
          podem rastrear itens como quanto tempo você gasta no site e as páginas
          visitadas, para que possamos continuar produzindo conteúdo atraente.
        </p>
        <p>
          Para mais informações sobre cookies do Google Analytics, consulte a
          página oficial do Google Analytics.
        </p>
        <p>
          As análises de terceiros são usadas para rastrear e medir o uso deste
          site, para que possamos continuar produzindo conteúdo atrativo. Esses
          cookies podem rastrear itens como o tempo que você passa no site ou as
          páginas visitadas, o que nos ajuda a entender como podemos melhorar o
          site para você.
        </p>
        <p>
          Periodicamente, testamos novos recursos e fazemos alterações subtis na
          maneira como o site se apresenta. Quando ainda estamos testando novos
          recursos, esses cookies podem ser usados para garantir que você receba
          uma experiência consistente enquanto estiver no site, enquanto
          entendemos quais otimizações os nossos usuários mais apreciam.
        </p>
        <p>
          À medida que vendemos produtos, é importante entendermos as
          estatísticas sobre quantos visitantes de nosso site realmente compram
          e, portanto, esse é o tipo de dados que esses cookies rastrearão. Isso
          é importante para você, pois significa que podemos fazer previsões de
          negócios com precisão que nos permitem analisar nossos custos de
          publicidade e produtos para garantir o melhor preço possível.
        </p>

        <h5>Compromisso do Usuário</h5>
        <p>
          O usuário se compromete a fazer uso adequado dos conteúdos e da
          informação que a FazBank oferece no site e com caráter enunciativo,
          mas não limitativo:
        </p>
        <ul>
          <li>
            Não se envolver em atividades que sejam ilegais ou contrárias à
            boa-fé à ordem pública;
          </li>
          <li>
            Não difundir propaganda ou conteúdo de natureza racista, xenofóbica,
            Onde dá a Bola ou azar, qualquer tipo de pornografia ilegal, de
            apologia ao terrorismo ou contra os direitos humanos;
          </li>
          <li>
            Não causar danos aos sistemas físicos (hardwares) e lógicos
            (softwares) da FazBank, de seus fornecedores ou terceiros, para
            introduzir ou disseminar vírus informáticos ou quaisquer outros
            sistemas de hardware, ou software que sejam capazes de causar danos
            anteriormente mencionados.
          </li>
        </ul>

        <h5>Mais informações</h5>
        <p>
          Esperemos que esteja esclarecido e, como mencionado anteriormente, se
          houver algo que você não tem certeza se precisa ou não, geralmente é
          mais seguro deixar os cookies ativados, caso interaja com um dos
          recursos que você usa em nosso site.
        </p>
        <p>Esta política é efetiva a partir de Março/2022.</p>

        <h5>Termos de Uso</h5>
        <h5>Termos</h5>
        <p>
          Ao acessar o site FazBank, concorda em cumprir estes termos de
          serviço, todas as leis e regulamentos aplicáveis e concorda ser
          responsável pelo cumprimento de todas as leis locais aplicáveis. Se
          você não concordar com algum desses termos, está proibido de usar ou
          acessar este site. Os materiais contidos neste site são protegidos
          pelas leis de direitos autorais e marcas comerciais aplicáveis.
        </p>

        <h5>Uso de Licença</h5>
        <p>
          É concedida permissão para baixar temporariamente uma cópia dos
          materiais (informações ou software) no site FazBank, apenas para
          visualização transitória pessoal e não comercial. Esta é a concessão
          de uma licença, não uma transferência de título e, sob esta licença,
          você não pode:
        </p>
        <ul>
          <li>Modificar ou copiar os materiais;</li>
          <li>
            Usar os materiais para qualquer finalidade comercial ou para
            exibição pública (comercial ou não comercial);
          </li>
          <li>
            Tentar descompilar ou fazer engenharia reversa de qualquer software
            contido no site FazBank;
          </li>
          <li>
            Remover quaisquer direitos autorais ou outras notações de
            propriedade dos materiais; ou
          </li>
          <li>
            Transferir os materiais para outra pessoa ou "espelhe" os materiais
            em qualquer outro servidor.
          </li>
        </ul>
        <p>
          Esta licença será automaticamente rescindida se você violar alguma
          dessas restrições e poderá ser rescindida pela FazBank a qualquer
          momento. Ao encerrar a visualização desses materiais ou após o término
          desta licença, você deve apagar todos os materiais baixados em sua
          posse, seja em formato eletrônico ou impresso.
        </p>

        <h5>Isenção de responsabilidade</h5>
        <p>
          Os materiais no site da FazBank são fornecidos "como estão". FazBank
          não oferece garantias, expressas ou implícitas, e, por este meio,
          isenta e nega todas as outras garantias, incluindo, sem limitação,
          garantias implícitas ou condições de comercialização, adequação a um
          fim específico ou não violação de propriedade intelectual, ou outra
          violação de direitos.
        </p>
        <p>
          Além disso, a FazBank não garante ou faz qualquer representação
          relativa à precisão, aos resultados prováveis ou à confiabilidade do
          uso dos materiais em seu site, ou de outra forma relacionado a esses
          materiais, ou em sites vinculados a este site.
        </p>

        <h5>Limitações</h5>
        <p>
          Em nenhum caso a FazBank ou seus fornecedores serão responsáveis por
          quaisquer danos (incluindo, sem limitação, danos por perda de dados ou
          lucro ou devido a interrupção dos negócios) decorrentes do uso ou da
          incapacidade de usar os materiais da FazBank, mesmo que a FazBank ou
          um representante autorizado da FazBank tenha sido notificado
          oralmente, ou por escrito da possibilidade de tais danos. Como algumas
          jurisdições não permitem limitações em garantias implícitas, ou
          limitações de responsabilidade por danos consequentes, ou incidentais,
          essas limitações podem não se aplicar a você.
        </p>

        <h5>Precisão dos materiais</h5>
        <p>
          Os materiais exibidos no site da FazBank podem incluir erros técnicos,
          tipográficos ou fotográficos. FazBank não garante que qualquer
          material em seu site seja preciso, completo ou atual. FazBank pode
          fazer alterações nos materiais contidos em seu site a qualquer
          momento, sem aviso prévio. No entanto, FazBank não se compromete a
          atualizar os materiais.
        </p>

        <h5>Links</h5>
        <p>
          A FazBank não analisou todos os sites vinculados ao seu site e não é
          responsável pelo conteúdo de nenhum site vinculado. A inclusão de
          qualquer link não implica endosso pela FazBank do site. O uso de
          qualquer site vinculado é por conta e risco do usuário.
        </p>

        <h5>Modificações</h5>
        <p>
          A FazBank pode revisar estes termos de serviço do site a qualquer
          momento, sem aviso prévio. Ao usar este site, você concorda em ficar
          vinculado à versão atual desses termos de serviço.
        </p>

        <h5>Lei aplicável</h5>
        <p>
          Estes termos e condições são regidos e interpretados conforme as leis
          da FazBank e você se submete irrevogavelmente à jurisdição exclusiva
          dos tribunais naquele estado ou localidade.
        </p>
      </div>
    </div>
  );
};

export default SecurityUses;
