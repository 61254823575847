import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";

const ContratoPJ = () => {
  const [LandingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, [LandingPageData]);

  return (
    <>
      <PageTitle title="Contrato pessoa Juridica" />
      <div className="text-just">
        <h5>Contrato FazBank</h5>

        <p>
          Este contrato definirá as condições gerais aplicáveis à sua CONTA DE
          PAGAMENTO do FAZ BANK CAPITAL BRASIL S/A, inscrita no CNPJ{" "}
          <span class="highlight">35.504.220/0001-37</span>, localizado na Rua
          Eudoro Berlink, 37, Moinhos de Vento, Porto Alegre/RS, CEP 9045-0030,
          neste contrato qualificado pelo nome fantasia FazBank.
        </p>

        <p>
          Cabe esclarecer previamente que a ativação de sua conta de pagamento
          dependerá da análise e aprovação das informações encaminhadas por você
          no momento de seu cadastro.
        </p>

        <p class="highlight">
          ATENÇÃO: A adesão a esse contrato significa que você concorda com a
          totalidade das regras estabelecidas nesse documento. Para dúvidas e
          esclarecimentos, os nossos contatos serão por meio do canal de
          atendimento, através do e-mail{" "}
          <a href="mailto:sac@Mettabanking.com.br">sac@Mettabanking.com.br</a>.
        </p>

        <h5>CONDIÇÕES APLICÁVEIS À CONTA</h5>

        <p>Abertura da Conta</p>

        <p>
          É condição para a abertura e manutenção da conta que a Empresa seja
          regularmente constituída, com CNPJ válido e ativo no momento do
          cadastro, que se encontre em plena atividade econômica e que possua
          sede e representante legal com domicílio no país, e ainda que seu
          representante legal (Usuário Master) seja previamente cadastrado na
          instituição.
        </p>

        <p>
          A solicitação da abertura da Conta de pagamento do FazBank deve se dar
          mediante:
        </p>
        <ul>
          <li>(i) acesso à Plataforma Digital do FazBank;</li>
          <li>
            (ii) realização de todos os passos indicados na aludida Plataforma
            Digital para cadastro junto ao FazBank, incluindo, mas não se
            limitando a:
            <ul>
              <li>
                (ii.1) preenchimento da Proposta, com indicação da pessoa física
                que atuará como Usuário Master autorizado a acessar o Aplicativo
                FazBank e movimentar a conta em seu nome;
              </li>
              <li>(ii.2) envio de documentação cadastral exigida; e</li>
              <li>(ii.3) criação de senhas pessoais e intransferíveis.</li>
            </ul>
          </li>
        </ul>

        <p>
          Para o aludido Cadastro da Empresa junto ao FazBank deverão ser
          fornecidas, pelo menos, as seguintes informações/documentos:
        </p>
        <ul>
          <li>firma ou denominação social;</li>
          <li>atividade principal;</li>
          <li>forma e data de constituição;</li>
          <li>
            documento de registro da empresa (como Contrato Social, Estatuto
            Social, Requerimento de Empresário, dentre outros);
          </li>
          <li>
            informações relativas a administradores, mandatários ou prepostos
            autorizados a executar instruções de pagamento (Usuário Master); e
          </li>
          <li>número de inscrição no CNPJ/MF;</li>
          <li>faturamento dos últimos 12 meses ou previsão de faturamento;</li>
        </ul>

        <p>
          Os dados inseridos durante o cadastro deverão ser completos, com
          informações precisas, idôneas, verdadeiras e exatas, sendo a Empresa a
          única responsável pelas informações fornecidas, inclusive aquelas
          concernentes à comprovação de faturamento e patrimônio.
        </p>

        <p>
          A Empresa e, quando aplicável, o Usuário Master, deverá enviar imagens
          legíveis e claras dos documentos solicitados, que podem ser obtidas
          por meio da digitalização ou registro fotográfico desses documentos,
          no formato indicado pelo FazBank. Os arquivos incompatíveis ou que
          apresentem qualidade abaixo da adequada poderão ser rejeitados, a
          exclusivo critério do FazBank.
        </p>

        <p>
          Os documentos que serão digitalizados ou fotografados não podem estar
          rasgados e/ou apresentar rasuras, cortes, obstruções, bem como
          elementos (letras, números, fotografias, assinaturas ou outros)
          apagados, desfocados, e/ou suprimidos de qualquer forma. Além disso, a
          Empresa ou o Usuário Master, quando aplicável, não poderá, em hipótese
          alguma, apresentar documentos originais adulterados ou cópias de
          documentos que sejam diferentes dos originais, bem como documentos de
          identificação que não sejam de sua titularidade.
        </p>

        <p>
          Em observância à regulamentação aplicável, o FazBank adota certos
          procedimentos e controles, incluindo a Proposta, para verificar e
          validar a identidade e qualificação da Empresa e, quando aplicável, do
          Usuário Master, sem necessariamente se limitar às informações
          fornecidas pela Empresa, inclusive mediante confrontação de tais
          informações com aquelas disponíveis em bancos de dados públicos e
          privados. Dessa forma, tanto durante o processo de cadastro quanto
          após a abertura da Conta, o FazBank poderá solicitar à Empresa
          informações e documentos complementares que julgar necessários,
          inclusive para fins de atualização, especialmente informações
          necessárias para a prevenção das práticas de “lavagem” de dinheiro e
          financiamento do terrorismo.
        </p>

        <p>
          Na hipótese de o usuário solicitar a aprovação de limite de crédito e
          a liberação de recursos, será necessário a ciência e a assinatura de
          contrato específico para a reportada transação de crédito, de acordo
          com a modalidade contratada.
        </p>

        <p>
          Ao solicitar a abertura da Conta, a Empresa reconhece a validade
          jurídica do uso dos Canais de Atendimento e do Aplicativo FazBank para
          manifestação de vontade e consentimento, inclusive para fins de
          contratação de serviços e produtos, bem como para movimentação da
          Conta e realização de Transações.
        </p>

        <p>
          Uma vez aprovada a criação da Conta de Pagamento, independentemente da
          efetivação de um primeiro aporte de recursos, fica subentendido que a
          Empresa está de acordo com todos os termos e condições deste Contrato,
          dos Termos de Uso e da Política de Privacidade, ambos disponíveis em{" "}
          <a href="https://www.Mettabanking.com.br">www.Mettabanking.com.br</a>.
        </p>

        <h3>Informações Cadastrais e Autorizações</h3>

        <p>
          A Empresa confirma que forneceu as informações e documentos
          necessários, solicitados pelo FazBank de acordo com a regulamentação
          aplicável, e que, independentemente de qualquer validação por parte do
          FazBank, é a única responsável por sempre fornecer informações exatas,
          verdadeiras, completas e fidedignas.
        </p>

        <p>
          A Empresa se compromete a manter seu cadastro junto ao FazBank
          atualizado, inclusive no que diz respeito aos dados de seus
          representantes legais e Usuário Master, além de comunicar sobre
          qualquer alteração em suas informações cadastrais. Essa comunicação
          deverá ser feita em, no máximo, 10 (dez) dias contados da referida
          alteração, por e-mail{" "}
          <a href="mailto:sac@fazbank.com.br">sac@fazbank.com.br</a>.
        </p>

        <p>
          Não havendo comunicação de qualquer mudança de endereço, inclusive
          eletrônico ou telefone da Empresa, serão considerados como recebidos,
          para todos os efeitos, os avisos e correspondências enviados para o
          último endereço constante dos registros do FazBank e/ou Ficha
          Cadastral.
        </p>

        <p>
          Caso o FazBank verifique que as informações fornecidas estão
          desatualizadas ou são inexatas, falsas ou incompletas, poderá:
        </p>
        <ul>
          <li>(i) não aprovar a contratação de outros produtos ou serviços;</li>
          <li>(ii) suspender a movimentação da Conta até sua regularização;</li>
          <li>(iii) encerrar a Conta; e/ou</li>
          <li>
            (iv) comunicar às autoridades competentes sobre essa irregularidade,
            sempre de acordo com a legislação aplicável.
          </li>
        </ul>

        <h3>Autorização e concessão de poderes – Usuário Master</h3>

        <p>
          No momento do preenchimento da Proposta no Aplicativo FazBank, a
          Empresa informa a pessoa física que atuará como Usuário Master. Ao
          fazê-lo, a Empresa:
        </p>
        <ul>
          <li>
            (i) de forma irrevogável e irretratável, nomeia e constitui o
            Usuário Master, devidamente qualificado na Proposta, como seu
            bastante representante/procurador para, em seu nome, acessar o
            Aplicativo FazBank, movimentar a conta e contratar os produtos e
            serviços ali disponíveis;
          </li>
          <li>
            (ii) autoriza o envio dos dados de acesso à Conta ao Usuário Master;
          </li>
          <li>
            (iii) autoriza expressamente tal usuário a operar, em seu nome e de
            forma irrestrita, o Aplicativo FazBank;
          </li>
          <li>
            (iv) declara e garante que o Usuário Master está devidamente
            autorizado e possui plena capacidade para acessar o Aplicativo,
            movimentar a Conta FazBank e/ou contratar produtos e serviços em
            nome da Empresa, cujos poderes foram outorgados nos atos
            constitutivos da empresa ou através de procurações, sob pena de
            responsabilização da Empresa pela execução dos atos praticados pelo
            Usuário Master.
          </li>
        </ul>

        <h3>Vigência e Resilição</h3>

        <p>
          Este contrato é celebrado por prazo indeterminado, produzindo efeitos
          a partir da aprovação da abertura da Conta FazBank, independente de
          haver saldo positivo ou negativo.
        </p>

        <p>
          O presente contrato poderá ser resilido a qualquer tempo, por qualquer
          uma das Partes, mediante notificação expressa da outra Parte com
          antecedência mínima de 30 (trinta) dias.
        </p>

        <p>
          Adicionalmente, o FazBank poderá resilir o contrato sem notificação
          prévia caso sejam verificadas infrações à legislação aplicável, ao
          presente contrato, aos Termos de Uso, à Política de Privacidade e
          demais documentos aplicáveis, bem como em situações que comprometam a
          segurança e integridade da Plataforma Digital e do Aplicativo FazBank,
          das Transações ou do próprio FazBank.
        </p>

        <p>
          Em qualquer hipótese de resilição, a Empresa fica obrigada a honrar
          com todas as suas obrigações pendentes, incluindo, mas não se
          limitando a, saldar eventuais débitos junto ao FazBank.
        </p>

        <p>
          Na hipótese de encerramento de conta por parte da Empresa, seja por
          resilição ou qualquer outro motivo, a Empresa deverá, no prazo de até
          10 (dez) dias corridos, solicitar a transferência do saldo
          remanescente para conta bancária de sua titularidade, mantida em outra
          instituição financeira.
        </p>

        <p>
          Caso não o faça, o FazBank poderá transferir o saldo para conta
          bancária de titularidade da Empresa, que constar em seu cadastro, sem
          prejuízo de novas tentativas para que o FazBank realize a comunicação
          prévia da Empresa sobre essa transferência.
        </p>

        <p>
          Havendo dúvidas, esclarecimentos ou solicitações, a Empresa pode
          entrar em contato conosco através do e-mail{" "}
          <a href="mailto:sac@Mettabanking.com.br">sac@Mettabanking.com.br</a>{" "}
          ou consultar o site{" "}
          <a href="https://www.Mettabanking.com.br">www.Mettabanking.com.br</a>.
        </p>
      </div>
    </>
  );
};

export default ContratoPJ;
