import React from "react";

export const LdServices = (props) => {
  // Verifica se props.data existe, com fallback para objeto vazio
  const data = props.data || {};
  // Suporte para paragraph, se existir
  const paragraph = data.paragraph || "";
  // Verifica se as subpropriedades são arrays, com fallback para arrays vazios
  const line = Array.isArray(data.line) ? data.line : [];
  const line2 = Array.isArray(data.line2) ? data.line2 : [];
  const line3 = Array.isArray(data.line3) ? data.line3 : [];

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>para você e para sua empresa</h2>
          {/* Exibe o paragraph, se existir */}
          {paragraph ? <p>{paragraph}</p> : null}
        </div>

        {/* Primeira linha de serviços */}
        <div className="row d-flex-start">
          {line.length > 0 ? (
            line.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>

        {/* Segunda linha de serviços */}
        <div className="row d-flex-start">
          {line2.length > 0 ? (
            line2.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>

        {/* Terceira linha de serviços */}
        <div className="row d-flex-start">
          {line3.length > 0 ? (
            line3.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-4 col-xs-12">
                <i className={d.icon}></i>
                <div className="service-desc">
                  <h3>{d.name}</h3>
                  <p>{d.text}</p>
                </div>
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};
