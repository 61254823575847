import React, { useState, useEffect } from "react";
import { PageTitle } from "./pageTitle";
import JsonData from "../data/data.json";
import "../App.css";
import { BodyContrato } from "./bodycontrato";

const ContratoPF = () => {
  const [LandingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, [LandingPageData]);

  return (
    <>
      <PageTitle title="Contrato pessoa Física" />
      <BodyContrato />
    </>
  );
};

export default ContratoPF;
